@import url("../../../../../App.css");

.round-order-nfc-fab-container-icon {
  display: flex;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
