@import url("../../../App.css");
.profile-img-container {
  width: 180px;
  height: 180px;
}
.profile-img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 4px solid #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.banner-img-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.banner-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-img--parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -2.5rem; */
  margin-top: 10px;
}

.profile-img--parent-container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -2.5rem; */
  margin-top: -60px;
}

.card-logo-parent-container {
  display: flex;
  flex-direction: row-reverse;
  /* margin-top: -2.5rem; */
  margin-top: 10px;
  margin-right: 10px;
}

.card-logo-img-container {
  width: 150px;
  height: 150px;
}

.card-logo-img-container img {
  width: 100%;
  height: 100%;
  border-radius: 15%;
  object-fit: contain;
  object-position: center;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.uc-name-label {
  font-size: 1.7rem;
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
}

.uc-email-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.uc-address-container {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  align-items: center;
  margin: 7px 1px;
}

.uc-address-container-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.uc-address-container1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.uc-company-label {
  text-align: center;
  align-self: center;
  font-size: 17px;
  font-weight: 600;
}

.uc-job-title-label {
  text-align: center;
  align-self: center;
  font-size: 17px;
}

.uc-ic-img-container {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

.cmc-content-container {
  width: 80%;
  align-self: center;
}

.uc-blue-label {
  color: var(--blue);
}

.uc-a-link {
  text-decoration: none;
  font-size: 16.5px;
  color: var(--black);
  margin-left: 5px;
}

.cmc-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-color: var(--pastel-001);
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  margin: 10px;
  padding: 7px 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.uc-email-container-mt {
  margin-top: 10px;
}

.link-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.link-img-container {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.link-text-container {
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
}

.type-label {
  color: var(--orange);
}

.card-preview-container {
  background-color: #f7f7f7;
  margin: 7px;
  border-radius: 5px;
  border: 4px solid #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.card-icon-text {
  margin-left: 10px;
  font-size: 16px;
  color: var(--black);
}

@media (max-width: 767px) {
  .profile-img-container {
    width: 100px;
    height: 100px;
  }
  .uc-name-label {
    font-size: 1.5rem;
  }
  .cmc-content-container {
    width: 95%;
    align-self: center;
  }
  .banner-img-container {
    height: 120px;
  }

  .card-logo-img-container {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .cmc-content-container {
    width: 90%;
    align-self: center;
  }
  .banner-img-container {
    height: 170px;
  }
  .profile-img-container {
    width: 150px;
    height: 150px;
  }

  .card-logo-img-container {
    width: 130px;
    height: 130px;
  }
}
