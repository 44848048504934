@import url("../../../../../App.css");

.ncol-detail-container-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ncol-detail-container {
  font-size: 14px;
  color: var(--blue-general);
  border-color: var(--gray);
  border-width: 0.75px;
  border-radius: 4px;
  border-style: solid;
  padding: 5px 20px;
  width: fit-content;
  cursor: pointer;
}
