@import url("../../../App.css");

.screen-label {
  font-size: 3.4em;
  color: var(--heading-color1);
}

@media (max-width: 767px) {
  .screen-label {
    font-size: 1.7em;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .screen-label {
    font-size: 3em;
  }
}
