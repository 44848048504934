@import url("../../../App.css");
.contentAreaSignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.logo-img-contain {
  height: 110px;
  margin-bottom: 10px;
  aspect-ratio: 2/1;
}

.img-contain {
  width: 12%;
  height: 5%;
  margin-bottom: 1rem;
}

.sub-text {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  color: var(--black);
}

.sign-up-form {
  width: 90%;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.input {
  width: 49%;
  font-size: 14px;
}

.input-full {
  width: 100%;
  font-size: 14px;
}

.confirmPass {
  font-size: 15px;
}

.input-text {
  font-size: 14px;
  color: black;
}

.mobile-input {
  border-color: var(--red);
  border-width: 1px;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.button {
  width: 40%;
  background-color: var(--orange);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
}

.button:hover {
  color: var(--orange);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--orange);
  border-width: 1px;
}

.su-select {
  width: 49%;
  font-size: 14px;
}

.checkbox-label {
  margin-left: 10px;
  font-size: 14px;
}

.paper-container {
  background-color: "#fbfbfb";
  width: 90%;
  padding: 1rem 1.2rem;
}

@media (max-width: 767px) {
  .button {
    font-size: 14px;
  }
  .sub-text {
    font-size: 14px;
  }

  .img-contain {
    width: 40%;
    height: 40%;
  }

  .logo-img-contain {
    height: 100px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .button {
    font-size: 15px;
  }
  .sub-text {
    font-size: 15px;
  }

  .img-contain {
    width: 40%;
    height: 40%;
  }

  .logo-img-contain {
    height: 120px;
  }
}
