@import url("../../../App.css");

.vc-item-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.vc-screen-label {
  align-self: flex-start;
  margin-left: 15px;
}
