@import url("../../App.css");

.error-container {
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;
}

.error-code {
  font-size: 10rem;
  font-weight: bold;
  color: var(--button-orange);
}

@media (max-width: 600px) {
  .error-code {
    font-size: 6rem;
  }

  a {
    font-size: 14px;
  }
}
