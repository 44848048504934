@import url("../../../../App.css");

.nts-card-container-one {
  width: 321.25px;
  height: 188.97px;
  background: #000;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.nts-card-container-one-qr-container {
  align-self: flex-end;
  margin-right: 10px;
}

.nts-card-container-one-name-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nts-card-container-one-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--white);
  margin: 0;
  padding: 0;
  font-family: "RobotoCondensed", sans-serif;
  text-align: center;
}

.nts-card-container-one-designation {
  font-size: 11px;
  color: var(--white);
  margin: 0;
  padding: 0;
  font-weight: 300;
  letter-spacing: 1.4px;
  font-family: "RobotoCondensed", sans-serif;
  text-align: center;
}

.nts-card-container-one-other-container {
  bottom: 0;
}

.nts-card-container-one-other {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nts-card-container-one-other-address {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}

.nts-card-container-one-other-text {
  font-size: 9px;
  color: var(--white);
  letter-spacing: 0.5px;
  font-weight: 200;
  font-family: "RobotoCondensed", sans-serif;
  margin-left: 5px;
  text-align: center;
}

.nts-card-container-one1 {
  width: 321.25px;
  height: 188.97px;
  background: #c0c0c0;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.nts-card-container-one-name1 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 0;
  font-family: "RobotoCondensed", sans-serif;
  text-align: center;
}

.nts-card-container-one-designation1 {
  font-size: 11px;
  color: #000;
  margin: 0;
  padding: 0;
  font-weight: 300;
  letter-spacing: 1.4px;
  font-family: "RobotoCondensed", sans-serif;
  text-align: center;
}

.nts-card-container-one-other1 {
  font-size: 9px;
  color: #000;
  margin: 0;
  letter-spacing: 0.5px;
  padding: 0;
  font-weight: 200;
  font-family: "RobotoCondensed", sans-serif;
  text-align: center;
  vertical-align: bottom;
}

.nts-card-container-two {
  width: 321.25px;
  height: 188.97px;
  background: #faf7f5;
  display: flex;
  flex-direction: column;
  padding: 5px 7px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.nts-card-container-two-name-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  flex: 1;
}

.nts-card-container-two-name {
  font-size: 30px;
  font-weight: 500;
  color: navy;
  margin: 0;
  padding: 0;
  font-family: "Gistesy", sans-serif;
}

.nts-card-container-two-designation {
  font-size: 14px;
  color: navy;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  font-family: "JosefinSans", sans-serif;
}

.nts-card-container-two-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  bottom: 0;
}

.nts-card-container-two-qr-container {
  align-self: flex-end;
  margin-right: 10px;
}

.nts-card-container-two-other-container {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: flex-end;
  bottom: 0;
}

.nts-card-container-two-other-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.nts-card-container-two-other-row-address {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 84%;
}
.nts-card-container-two-other-text {
  font-size: 11px;
  color: navy;
  margin-left: 5px;
  letter-spacing: 0.5px;
  padding: 0;
  font-family: "JosefinSans", sans-serif;
}

.nts-card-container-three {
  width: 321.25px;
  height: 188.97px;
  background: #fff;
  display: flex;
  flex-direction: row;
  padding: 5px 7px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.nts-card-container-three-qr-parent-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nts-card-container-three-qr-container {
  background: #fff;
}

.nts-card-three-vertical-seprator {
  height: 150px;
  width: 3px;
  background: #6f2884;
  align-self: center;
  margin-right: 15px;
  border-radius: 10px;
}

.nts-card-container-three-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nts-card-container-three-name {
  font-size: 20px;
  font-weight: 700;
  color: #6f2884;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.nts-card-container-three-designation {
  font-size: 12px;
  color: #6f2884;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}

.nts-card-container-three-other-container {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  margin-top: 20px;
}

.nts-card-container-three-other {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.nts-card-container-three-other-text {
  font-size: 9px;
  color: #6f2884;
  margin-left: 5px;
  letter-spacing: 0.5px;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.nts-select-design-label-container {
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nts-select-design-label {
  text-align: center;
  font-size: 14px;
  color: var(--black);
  width: fit-content;
  padding: 5px 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  cursor: pointer;
}

.nts-select-design-label-selected {
  text-align: center;
  font-size: 14px;
  color: var(--white);
  width: fit-content;
  padding: 5px 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  background: var(--gray);
  cursor: pointer;
}

.nts-card-container-one-icon-container {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  object-fit: cover;
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}
