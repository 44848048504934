@import url("../../../App.css");
.contentAreaSignIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sign-in-screen-label {
  font-size: 26px;
  font-weight: 600;
  color: var(--red);
  margin-top: 1.5em;
}

.sign-in-sub-text {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  color: var(--black);
}

.sign-in-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.sign-in-img {
  height: 100%;
  width: 100%;
}

.sign-in-form {
  width: 80%;
}

.sign-in-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4em;
}

.sign-in-other-opt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 14px;
}

.sign-in-col-button-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-button {
  width: 45%;
  background-color: var(--orange);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
}

.sign-in-button:hover {
  color: var(--orange);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--orange);
  border-width: 1px;
}

.sign-up-button {
  width: 45%;
  background-color: var(--white);
  font-size: 16px;
  color: var(--orange);
  height: 45px;
  font-weight: 500;
  border-radius: 4px;
  border-color: var(--orange);
  border-width: 1px;
}

.sign-up-button:hover {
  color: var(--white);
  background-color: var(--orange);
  border-radius: 4px;
  border-color: var(--orange);
  border-width: 1px;
}

.forgot-password-label {
  color: var(--blue);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}

.sign-in-input {
  font-size: 14px;
}

@media (max-width: 767px) {
  .sign-in-button {
    font-size: 14px;
  }
  .sign-up-button {
    font-size: 14px;
  }
  .sign-in-sub-text {
    font-size: 14px;
  }

  .sign-in-img-contain {
    width: 40%;
    height: 40%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .sign-in-button {
    font-size: 14px;
  }
  .sign-up-button {
    font-size: 14px;
  }
  .sign-in-sub-text {
    font-size: 15px;
  }

  .sign-in-img-contain {
    width: 40%;
    height: 40%;
  }
}
