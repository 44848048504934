@import url("../../../../App.css");

.nts-card-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.nts-card-parent-container {
  margin-top: 5px;
  margin-right: 5px;
}

.nts-quantity-parent-view {
  margin-top: 1.2rem;
}

.nts-pricing-label {
  font-size: 14px;
  color: var(--blue-general);
}
