@import url("../../../App.css");

.screenFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 5px;
  background-color: #f2f2ff;
  margin-top: 20px;
  cursor: pointer;
  width: 100%;
}

.colElementItemCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-logo-container {
  height: 150px;
}

.h-sub-link-text {
  font-size: 15px;
  color: var(--black);
  margin-bottom: 4px;
}

.colElementCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.colElementHeadingLogo {
  font-size: 16px;
  align-self: center;
  margin-bottom: 15px;
  color: var(--heading-color1);
  font-weight: 600;
}

.colElement14Label {
  font-size: 15px;
  color: var(--black);
}

@media (max-width: 700px) {
  .colElementItemCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .colElementCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .colElementHeadingLogo {
    align-self: flex-start;
    margin-top: 1.2rem;
  }

  .colElementHeadingLogoLocation {
    align-self: flex-start;
  }
}
