@import url("../../../App.css");
.alias-success {
  color: var(--green-success);
  font-size: 13.5px;
}

.alias-error {
  color: var(--red-error);
  font-size: 13.5px;
}

.phone-input-container {
  margin-bottom: 10px;
  margin-top: 5px;
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.phone-input-container-full {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.phone-input-item-100 {
  font-size: 15px;
  padding: 5px 0;
  width: 100%;
}

.phone-input-item-100-wa {
  font-size: 14px;
  width: 100%;
  margin-bottom: 1rem;
}

.phone-input-item {
  font-size: 15px;
  padding: 5px 0;
  flex-grow: 2;
}

.phone-input-item-label {
  font-size: 15px;
}

.phone-checkbox {
  margin-left: 10px;
}

.ac-input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ac-add-img-container {
  margin-left: 10px;
  width: 48px;
  height: 44px;
}

.ac-add-button {
  font-size: 14px;
  color: var(--lsub-heading-color1);
  background-color: var(--white);
  border-color: var(--sub-heading-color1);
  border-radius: 2px;
  border-width: 0.75px;
  border-style: solid;
  border-width: 0.75px;
  margin-left: 10px;
}

.ac-add-button:hover {
  color: var(--white);
  background-color: var(--sub-heading-color1);
  border-color: var(--sub-heading-color1);
  border-radius: 2px;
  border-width: 0.75px;
  border-style: solid;
  border-width: 0.75px;
}

.ac-social-link-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ac-social-link-item {
  font-size: 15px;
  color: var(--black);
  margin-right: 10px;
}

.ac-social-link-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  border-color: var(--sub-heading-color1);
  border-style: solid;
  border-width: 0.75px;
  margin: 5px 5px;
  width: fit-content;
}

.mb-4 {
  margin-bottom: 4px;
}

.ac-social-link-img-container {
  width: 32px;
  height: 32px;
  margin-left: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.hint-label {
  font-size: 12px;
}

.ac-width-100 {
  width: 100%;
}

.banner {
  position: relative;
  /* allows the button to be positioned relatively to the banner */
  height: 300px;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-image-button {
  position: absolute;
  /* position the button absolutely within the banner */
  top: 10px;
  /* position the button 10px from the top */
  right: 10px;
  /* position the button 10px from the right */
  height: 38px;
  width: 38px;
}

.delete-image-button {
  position: absolute;
  /* position the button absolutely within the banner */
  top: 60px;
  /* position the button 10px from the top */
  right: 10px;
  /* position the button 10px from the right */
  height: 38px;
  width: 38px;
}

.edit-image-button-bl {
  position: absolute;
  /* position the button absolutely within the banner */
  top: 10px;
  /* position the button 10px from the top */
  right: 10px;
  /* position the button 10px from the right */
  height: 25px;
  width: 25px;
}

.delete-image-button-bl {
  position: absolute;
  /* position the button absolutely within the banner */
  top: 60px;
  /* position the button 10px from the top */
  right: 10px;
  /* position the button 10px from the right */
  height: 25px;
  width: 25px;
}

.ac-brandLogo {
  position: relative;
  /* allows the button to be positioned relatively to the banner */
  width: 70px;
  /* set width of the banner */
  height: 70px;
  /* set height of the banner */
  /* margin-top: -2.5rem; */
  margin-top: 10px;
  margin-left: auto;
}
.ac-brandLogo img {
  width: 100%;
  /* make the image fill the entire width of the banner */
  height: 100%;
  /* adjust the height of the image proportionally */
  object-fit: contain;
}

.profile {
  position: relative;
  /* allows the button to be positioned relatively to the banner */
  width: 180px;
  /* set width of the banner */
  height: 180px;
  /* set height of the banner */
  /* margin-top: -2.5rem; */
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.profile img {
  width: 100%;
  /* make the image fill the entire width of the banner */
  height: 100%;
  /* adjust the height of the image proportionally */
  object-fit: cover;
}

.phone-parent-container {
  margin-bottom: 10px;
}

.phone-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.phone-select-container {
  width: 30%;
}

.phone-field-container {
  width: 60%;
}

.phone-ext-container {
  width: 30%;
}

.phone-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.input-icon-container {
  width: 32px;
  height: 32px;
  margin-left: 20px;
  cursor: pointer;
}

.address-field-container {
  width: 70%;
}

.field-mb-0 {
  margin-bottom: 0px;
}

.phone-card-container-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 10px;
}

.phone-card-item-type {
  font-size: 12px;
  color: var(--gray);
}

.phone-card-item-field {
  font-size: 15px;
  color: var(--black);
}

.summary-container {
  font-size: 16px;
  color: var(--black);
  margin-top: 1rem;
  padding-left: 15px;
  padding-right: 15px;
}

.label-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding: 5px 10px;
  margin-top: 7px;
}

.label-icon-container {
  height: 36px;
  width: 36px;
}

.ac-card {
  padding: 5px 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .phone-input-item {
    width: 80%;
  }
  .banner {
    height: 200px;
  }
  .profile {
    border-style: solid;
    border-color: var(--gray);
    border-width: 0.75px;
    width: 100px;
    background-color: var(--white);
    /* set width of the banner */
    height: 100px;
    /* set height of the banner */
  }
  .ac-brandLogo {
    border-style: solid;
    border-color: var(--gray);
    border-width: 0.75px;
    width: 50px;
    background-color: var(--white);
    /* set width of the banner */
    height: 50px;
    /* set height of the banner */
  }
  .edit-image-button {
    height: 26px;
    width: 26px;
  }
  .delete-image-button {
    height: 26px;
    width: 26px;
  }
  .ac-add-img-container {
    margin-left: 10px;
    width: 38px;
    height: 34px;
  }
  .phone-field-container {
    width: 50%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .profile {
    width: 150px;
    /* set width of the banner */
    height: 150px;
    /* set height of the banner */
  }
  .ac-brandLogo {
    width: 60px;
    /* set width of the banner */
    height: 60px;
    /* set height of the banner */
  }
}
