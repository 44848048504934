@import url("../../../App.css");

.cutom-date-select {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  margin: 10px 0;
}

.cutom-date-range {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 5px 0;
}

.chart-container {
  width: "100%";
  height: 400px;
}

.content-container-bg {
  background-color: #f4f6f5;
}

.dash-card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  align-self: flex-start;
  flex-wrap: wrap;
}

.dash-card {
  width: 20em;
  background-color: var(--pastel-004);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  margin-top: 5px;
  padding: 5px;
}

.dash-header-label-left {
  font-size: 1em;
  align-self: flex-start;
  color: var(--white);
}

.dash-header-label-val {
  font-size: 3em;
  display: flex;
  flex-direction: row-reverse;
  color: var(--white);
  margin-top: 10px;
}

@media (max-width: 700px) {
  .dash-header-label-val {
    font-size: 2.2em;
    margin-top: 7px;
    display: flex;
    flex-direction: row-reverse;
  }

  .dash-card {
    margin-bottom: 7px;
    width: 14em;
    margin-top: 5px;
  }

  .dash-header-label-left {
    font-size: 0.8em;
  }

  .chart-container {
    height: 250px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .chart-container {
    height: 200px;
  }
}
