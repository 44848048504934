@import url("../../../App.css");

.hv-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  width: 100%;
  elevation: 1;
  padding: 0 10px;
}

.hv-logo-container {
  height: 70px;
  aspect-ratio: 2/1;
}

.hv-nav-link-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hv-nav-sign-in {
  font-size: 16px;
  color: var(--heading-color1);
  margin-right: 10px;
  cursor: pointer;
  text-decoration: underline;
  letter-spacing: 1px;
  text-underline-offset: 5px;
  text-decoration-thickness: 1.2px;
}

.hv-nav-create-account {
  font-size: 16px;
  color: var(--logo-yellow);
  margin-left: 5px;
  padding: 5px 15px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--logo-yellow);
  cursor: pointer;
  letter-spacing: 1px;
}

.hero {
  background: url("../../../assets/vms-banner.jpg") no-repeat center
    center/cover;
  height: 80vh;
  position: relative;
  color: #fff;
}
.hero .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  height: 100%;
}

.hero .content h1 {
  font-size: 2.7em;
  padding: 0 2rem;
  font-weight: 600;
}

.hero .content p {
  font-size: 16px;
  padding: 0 10px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.hero * {
  z-index: 10;
}

.get-started-btn {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: var(--logo-yellow);
  font-size: 16px;
  border-width: 0.75px;
  border-style: solid;
  border-radius: 4px;
  height: 45px;
  color: var(--logo-yellow);
  font-weight: 500;
  margin-top: 2rem;
}

.content-section-container {
  padding: 1rem 0.75rem;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.feature-section-container {
  padding: 1rem 0.75rem;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quote-section-container {
  padding: 1.5rem 1rem;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.quote-section-container h6 .label {
  font-size: 12px;
}

.feature-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.content-text {
  margin-top: 2rem;
}

.content-text2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .get-started-btn {
    font-size: 14px;
    margin-top: 5rem;
  }

  .hero .content h1 {
    font-size: 2em;
  }

  .hero .content p {
    font-size: 14px;
  }
}

@media (max-width: 567px) {
  .get-started-btn {
    font-size: 14px;
    margin-top: 10px;
  }

  .hero .content h1 {
    font-size: 1.5em;
  }

  .hero .content p {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .get-started-btn {
    font-size: 14px;
  }
}
