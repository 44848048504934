@import url("../../../App.css");

.loc-table-row-container {
  cursor: pointer;
}

.loc-list-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.loc-list-button {
  font-size: 13.5px;
  color: var(--sub-heading-color1);
  border-radius: 4px;
  border-style: solid;
  border-color: var(--sub-heading-color1);
  background-color: var(--white);
  padding: 2px 15px;
  border-width: 0.75px;
}

.loc-list-cta-icon {
  margin-bottom: 10px;
  margin-right: 25px;
  width: 24px;
  height: 24px;
}

.loc-list-card-name {
  margin-top: 5px;
  text-align: center;
  font-size: 17px;
}

.loc-list-card-name1 {
  margin-top: 5px;
  font-size: 17px;
}

.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.qr-name-label {
  font-size: 18px;
  font-weight: bold;
  margin: 2px 0px;
  text-align: center;
}

.qr-name-label-light {
  font-size: 16px;
  margin: 7px 0px;
  text-align: center;
}

.cl-fab-icon {
  width: 80%;
  height: 90%;
  align-self: center;
}

.cl-fab-container {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 70px;
  width: 70px;
  margin-right: 20px;
  margin-bottom: 5rem;
  border-radius: 10px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 233, 0.19);
}

.round-fab-container {
  display: flex;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 2em;
  bottom: 85px;
  border-radius: 50%;
  box-shadow: 0 3px 6px lightgrey;
  background-color: #fb8c00;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
}

.round-fab-container-icon {
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.download-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  text-align: center;
}
