@import url("../../../../App.css");

.od-name-label {
  text-align: center;
  font-size: 2.5rem;
  color: var(--pastel-004);
  margin-bottom: 20px;
  font-weight: 600;
}

.od-text-label {
  font-size: 16px;
  color: var(--black);
}
