@import url("../../../App.css");
.hp-container {
  overflow-y: scroll;
}

.hp-header-background {
  background-color: transparent;
  background-image: linear-gradient(180deg, #243128 0%, #f3c625 100%);
  height: 600px;
  clip-path: ellipse(80% 60% at 50% 30%);
  /* clip-path: polygon(
      0% 0%,
      0% 82.5%,
      1.69492% 84.3293%,
      3.38983% 86.1385%,
      5.08475% 87.9078%,
      6.77966% 89.6179%,
      8.47458% 91.25%,
      10.1695% 92.7862%,
      11.8644% 94.2098%,
      13.5593% 95.505%,
      15.2542% 96.6578%,
      16.9492% 97.6555%,
      18.6441% 98.487%,
      20.339% 99.1435%,
      22.0339% 99.6176%,
      23.7288% 99.9041%,
      25.4237% 100%,
      27.1186% 99.9041%,
      28.8136% 99.6176%,
      30.5085% 99.1435%,
      32.2034% 98.487%,
      33.8983% 97.6555%,
      35.5932% 96.6578%,
      37.2881% 95.505%,
      38.9831% 94.2098%,
      40.678% 92.7862%,
      42.3729% 91.25%,
      44.0678% 89.6179%,
      45.7627% 87.9078%,
      47.4576% 86.1385%,
      49.1525% 84.3293%,
      50.8475% 82.5%,
      52.5424% 80.6708%,
      54.2373% 78.8616%,
      55.9322% 77.0922%,
      57.6271% 75.3821%,
      59.322% 73.75%,
      61.017% 72.2138%,
      62.7119% 70.7902%,
      64.4068% 69.495%,
      66.1017% 68.3422%,
      67.7966% 67.3446%,
      69.4915% 66.513%,
      71.1864% 65.8565%,
      72.8814% 65.3824%,
      74.5763% 65.0959%,
      76.2712% 65%,
      77.9661% 65.0959%,
      79.661% 65.3824%,
      81.3559% 65.8565%,
      83.0509% 66.513%,
      84.7458% 67.3446%,
      86.4407% 68.3422%,
      88.1356% 69.495%,
      89.8305% 70.7902%,
      91.5254% 72.2138%,
      93.2203% 73.75%,
      94.9153% 75.3821%,
      96.6102% 77.0922%,
      98.3051% 78.8616%,
      100% 80.6708%,
      100% 0%
    );
    shape-outside: polygon(
      0% 0%,
      0% 82.5%,
      1.69492% 84.3293%,
      3.38983% 86.1385%,
      5.08475% 87.9078%,
      6.77966% 89.6179%,
      8.47458% 91.25%,
      10.1695% 92.7862%,
      11.8644% 94.2098%,
      13.5593% 95.505%,
      15.2542% 96.6578%,
      16.9492% 97.6555%,
      18.6441% 98.487%,
      20.339% 99.1435%,
      22.0339% 99.6176%,
      23.7288% 99.9041%,
      25.4237% 100%,
      27.1186% 99.9041%,
      28.8136% 99.6176%,
      30.5085% 99.1435%,
      32.2034% 98.487%,
      33.8983% 97.6555%,
      35.5932% 96.6578%,
      37.2881% 95.505%,
      38.9831% 94.2098%,
      40.678% 92.7862%,
      42.3729% 91.25%,
      44.0678% 89.6179%,
      45.7627% 87.9078%,
      47.4576% 86.1385%,
      49.1525% 84.3293%,
      50.8475% 82.5%,
      52.5424% 80.6708%,
      54.2373% 78.8616%,
      55.9322% 77.0922%,
      57.6271% 75.3821%,
      59.322% 73.75%,
      61.017% 72.2138%,
      62.7119% 70.7902%,
      64.4068% 69.495%,
      66.1017% 68.3422%,
      67.7966% 67.3446%,
      69.4915% 66.513%,
      71.1864% 65.8565%,
      72.8814% 65.3824%,
      74.5763% 65.0959%,
      76.2712% 65%,
      77.9661% 65.0959%,
      79.661% 65.3824%,
      81.3559% 65.8565%,
      83.0509% 66.513%,
      84.7458% 67.3446%,
      86.4407% 68.3422%,
      88.1356% 69.495%,
      89.8305% 70.7902%,
      91.5254% 72.2138%,
      93.2203% 73.75%,
      94.9153% 75.3821%,
      96.6102% 77.0922%,
      98.3051% 78.8616%,
      100% 80.6708%,
      100% 0%
    ); */
  padding: 1.5% 1.2%;
}

.hp-logo-container {
  height: 70px;
  aspect-ratio: 2/1;
}

.hp-login-button {
  border-radius: 10px;
  color: white;
  padding: 5px 3rem;
  font-size: 16px;
  background-color: #216036;
  border-color: #216036;
  border-width: 0.75px;
  border-style: solid;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 3px solid;
  border-color: red;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.overlayText {
  position: relative;
  bottom: 30px;
  left: 102px;
  font-size: 28px;
}

.hp-get-started-button {
  border-radius: 10px;
  color: white;
  width: 40%;
  font-size: 16px;
  background-color: var(--button-orange);
  border-color: var(--button-orange);
  border-width: 0.75px;
  border-style: solid;
  align-self: center;
  border-radius: 10px;
  color: white;
  padding: 5px 3rem;
  font-size: 17px;
  background-color: #216036;
  border-color: #216036;
  border-width: 0.75px;
  border-style: solid;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 3px solid;
  border-color: red;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.overlayText {
  position: relative;
  bottom: 30px;
  left: 102px;
  font-size: 28px;
}

.hp-get-started-button {
  border-radius: 10px;
  color: white;
  width: 40%;
  font-size: 16px;
  background-color: var(--button-orange);
  border-color: var(--button-orange);
  border-width: 0.75px;
  border-style: solid;
  align-self: center;
  border-radius: 10px;
  color: white;
  padding: 5px 3rem;
  font-size: 17px;
  background-color: #216036;
  border-color: #216036;
  border-width: 0.75px;
  border-style: solid;
}

.hp-login-button:hover {
  color: white;
  background-color: #216036;
  border-color: #216036;
  border-width: 0.75px;
  border-style: solid;
}

.hp-get-started-button {
  border-radius: 10px;
  color: white;
  width: 40%;
  font-size: 17px;
  background-color: #4a3d03;
  border-color: #4a3d03;
  border-width: 0.75px;
  border-style: solid;
  align-self: center;
}

.hp-get-started-button:hover {
  color: white;
  background-color: #4a3d03;
  border-color: #4a3d03;
  border-width: 0.75px;
  border-style: solid;
}

.hp-sub-title {
  color: white;
  font-size: 4rem;
  text-align: center;
}

.hp-sub-title-subheading {
  color: white;
  font-size: 20px;
  margin: 2.5rem 0 3rem 0;
  text-align: center;
}

.hp-app-title {
  color: white;
  font-weight: bolder;
}

.hp-nav-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.hp-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
}

.hp-how-it-work-card {
  background-color: transparent;
  background-image: linear-gradient(-90deg, #ffffff 0%, #f0efef 94%);
  margin: 10px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-how-it-work-card-title {
  font-size: 20px;
  margin: 20px 0;
  text-align: center;
  color: var(--sub-heading-color1);
}

.hp-how-it-work-card-description {
  font-size: 16px;
  margin-top: 1rem;
  text-align: center;
}

.hp-shadow-card-container {
  box-shadow: 0px 0px 29px -2px rgb(201 201 201 / 50%);
  margin: 5px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-title-h2 {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  color: var(--heading-color1);
}

.hp-title-h2-yellow {
  color: #f2c112;
}

.hp-title-h2-green {
  color: #266f3e;
}

.hp-title-quote {
  padding-top: 26px;
  padding-left: 26px;
  padding-right: 26px;
  text-align: center;
  font-size: 23px;
  color: #266f3e;
}

.hp-title-quote-click {
  font-weight: bolder;
}

.hp-title-sub-quote {
  margin-top: -15px;
  padding-left: 26px;
  padding-right: 26px;
  text-align: center;
  font-size: 20px;
}

.hp-title-h2-text {
  padding: 26px;
  text-align: center;
  font-size: 23px;
}

.hp-title-h2-text-pb {
  padding-bottom: 0px;
}

.hp-title-h2-text-pt {
  padding-top: 10px;
  color: #f2c112;
}

.hp-title-h2-text-2 {
  padding: 10px;
}

.hp-title-h2-text-container {
  padding: 2% 5%;
}

.hp-title-h2-text-container-2 {
  padding: 15px 2%;
}

.hp-title-made-by {
  color: #ee158d;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
}

.hp-explore-heading {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  color: var(--heading-color1);
}

.hp-img-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hp-section-container {
  padding: 1.5rem 0;
}

.hp-container-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hp-social-img-container {
  width: 90px;
  height: 90px;
}

.hp-support-label {
  font-size: 25px;
  line-height: 1;
  text-align: center;
}

.hp-support-detail {
  font-size: 18px;
  margin: 18px 0;
  text-align: center;
}

.hp-footer-app-label {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}

.hp-footer-card-container {
  margin: 5px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-footer-location-subtext {
  font-size: 18px;
  text-align: center;
}

.hp-carousel-container {
  padding: 0 5%;
  width: 100%;
}

.hp-login-image-container {
  height: 70px;
  aspect-ratio: 2/1;
}

.nav-item-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-product-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  text-decoration: underline;
  margin: 0px 10px;
  cursor: pointer;
  letter-spacing: 1px;
  text-underline-offset: 5px;
  text-decoration-thickness: 1.2px;
  text-shadow: #f3c625 1px 0 1px;
  padding: 5px;
}

.nav-menu-list-container {
  background-color: #f6f6f6;
}

@media (max-width: 767px) {
  .hp-sub-title {
    font-size: 2rem;
  }
  .hp-sub-title-subheading {
    font-size: 18px;
  }
  .hp-get-started-button {
    width: 60%;
  }
  .hp-title-h2 {
    font-size: 25px;
  }
  .hp-explore-heading {
    font-size: 25px;
  }
  .hp-title-h2-text {
    font-size: 16px;
  }
  .hp-title-sub-quote {
    font-size: 15px;
    margin-top: -15px;
  }
  .hp-title-quote {
    font-size: 17px;
  }
  .hp-header-background {
    height: 550px;
    clip-path: ellipse(97% 60% at 50% 30%);
  }
  .overlayText {
    font-size: 18px;
    left: 67px;
    bottom: 25px;
  }

  .nav-product-btn {
    font-size: 14.5px;
    margin: 0px 7px;
    letter-spacing: 0.75px;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    padding: 3px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .hp-header-background {
    height: 620px;
    clip-path: ellipse(89% 60% at 50% 30%);
  }
}
