@import url("../../../App.css");

.common-input-full-container {
  width: 100%;
}

.common-input-full {
  font-size: 14px;
  color: var(--black);
}

.common-input-margin {
  margin-bottom: 10px;
}

.required-field {
  font-size: 14px;
  color: var(--red-error);
  margin-left: 6px;
  font-weight: bolder;
}

.label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-hint-label {
  font-size: 12px;
  margin-top: 2px;
}
