@import url("../../App.css");

.fp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.fp-screen-label {
  font-size: 3.4em;
  color: var(--blue);
}

.fp-form {
  width: 70%;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 2.5em;
}

.fp-sub-text {
  font-size: 16px;
  margin-bottom: 2em;
  font-weight: 400;
  color: var(--black);
}

.fp-input {
  width: 100%;
  margin-bottom: 1.2em;
  font-size: 15px;
}

.fp-input-text {
  font-size: 15px;
  color: black;
}

.fp-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.fp-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.fp-button {
  width: 40%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
}

.fp-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.fp-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.fp-input-half {
  width: 48%;
  font-size: 15px;
}

@media (max-width: 767px) {
  .fp-button {
    font-size: 14px;
  }
  .fp-sub-text {
    font-size: 14px;
  }
  .fp-screen-label {
    font-size: 1.7em;
  }

  .fp-form {
    width: 90%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .fp-screen-label {
    font-size: 3em;
  }
  .fp-button {
    font-size: 15px;
  }
  .fp-sub-text {
    font-size: 15px;
  }

  .fp-form {
    width: 80%;
  }
}
