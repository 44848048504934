@import url("../../../../App.css");

.one-button-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.one-button {
  width: 120px;
  background-color: var(--sub-heading-color1);
  font-size: 16px;
  color: var(--white);
  height: 35px;
  font-weight: 500;
  border-radius: 4px;
}

.one-button:hover {
  background-color: var(--white);
  color: var(--sub-heading-color1);
  border-color: var(--sub-heading-color1);
  border-style: solid;
  border-width: 0.75px;
  border-radius: 4px;
}

.one-place-order-button {
  width: 220px;
  background-color: var(--sub-heading-color1);
  font-size: 16px;
  color: var(--white);
  height: 35px;
  font-weight: 500;
  border-radius: 4px;
}

.one-place-order-button:hover {
  background-color: var(--white);
  color: var(--sub-heading-color1);
  border-color: var(--sub-heading-color1);
  border-style: solid;
  border-width: 0.75px;
  border-radius: 4px;
}

.ci-receipt-list-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 2px;
  background-color: var(--white);
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  padding: 2px 5px;
}

.ci-receipt-number-label {
  font-size: 15px;
  color: var(--black);
}

.ci-invoice-warning {
  font-size: 13.4px;
  color: #7f7f7f;
  margin-top: 4px;
}