.fab-container {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column-reverse;
    position: fixed;
    right: 2em;
    bottom: 4em;
    // bottom: 2em;
  
    &.open {
      max-height: max-content;
    }
  
    li {
      border-radius: 50%;
      box-shadow: 0 3px 6px lightgrey;
      display: grid;
      place-items: center;
      margin: 8px 0;
      font-size: 28px;
      padding: 12px;
      cursor: pointer;
      position: relative;
    }
  
    .fab-button {
      background-color: #fb8c00;
      min-height: 60px;
      width: 60px;
      
      svg {
        fill: white;
      }
    }
  
    .fab-action {
      transform: translateY(50px) scale(0);
      transition: transform 300ms, opacity 300ms;
      opacity: 0;
      
    //   &:hover {
    //     .tooltip {
    //       transform: translateX(-100%) scale(1);
    //       opacity: 1;
    //     }
    //   }
    
  
      &.open {
        transform: translateY(0) scale(1);
        opacity: 1;
      }
  
      .tooltip {
        padding: 4px 6px;
        font-size: 12px;
        position: absolute;
        left: -32px;
        transform: translateX(-90%) scale(1);
        background-color: #fff3e0;
        border-radius: 4px;
        color: #353b48;
        opacity: 1;
        font-weight: 600;
        transition: transform 300ms, opacity 300ms;
        user-select: none;
      }

      .fab-icon-div{
        width: 50px;
        height: 50px;
      }
    }
  }