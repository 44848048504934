@import url("../../../App.css");

.business-name-label {
  font-size: 2rem;
  font-weight: 600;
  margin: 10px 0px;
  color: var(--sub-heading-color1);
  text-align: center;
}

.building-address-label {
  font-size: 1.6rem;
  margin-top: -10px;
  margin-bottom: 10px;
  color: var(--sub-heading-color1);
  text-align: center;
}

.success-img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.success-img-label {
  font-size: 2rem;
  color: var(--sub-heading-color1);
  margin: 10px 5px;
  font-weight: 600;
  text-align: center;
}

.load-camera-btn {
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 2px;
  border-width: 0.75px;
  width: fit-content;
  border-style: solid;
  border-color: var(--button-color-green);
  color: var(--button-color-green);
  background-color: var(--white);
  cursor: pointer;
}

.video-player-container {
  width: 180px;
  height: 180px;
}

.capture-img-container {
  width: 180px;
  height: 180px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .business-name-label {
    font-size: 1.5rem;
  }

  .building-address-label {
    font-size: 1.2rem;
  }

  .success-img {
    width: 200px;
    height: 200px;
  }

  .success-img-label {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .business-name-label {
    font-size: 1.7rem;
  }

  .success-img {
    width: 240px;
    height: 240px;
  }

  .success-img-label {
    font-size: 1.7rem;
  }

  .building-address-label {
    font-size: 1.4rem;
  }
}
