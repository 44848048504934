@import url("../../../App.css");

.cqc-icon-parent-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.cqc-icon-container {
  width: 40px;
  height: 40px;
}

.cqc-item-container {
  width: 97px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 5px;
  border-color: var(--gray);
  border-style: solid;
  border-width: 0.75px;
  padding: 5px 0px;
  text-align: center;
  cursor: pointer;
}

.cqc-item-selected-container {
  width: 97px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 5px;
  border-color: var(--logo-yellow);
  border-style: solid;
  border-width: 2px;
  padding: 5px 0px;
  text-align: center;
  cursor: pointer;
}

.cqc-item-lilac-container {
  border-color: #c8a2c8;
  width: 105px;
}

.cqc-item-visitor-container {
  border-color: #0077ff;
  width: 110px;
}

.cqc-item-qme-container {
  border-color: #7e3dff;
  width: 97px;
}

.cqc-item-just-gomo-container {
  border-color: #10ad32;
  width: 97px;
}

.cqc-item-orange-container {
  border-color: #f58233;
  width: 105px;
}

.cqc-item-label {
  font-size: 14px;
  color: var(--heading-color);
  margin-top: 5px;
}

.cqc-item-selected-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--logo-yellow);
  margin-top: 5px;
}

.cqc-item-lilac-label {
  font-size: 14px;
  font-weight: 500;
  color: #b989b9;
  margin-top: 5px;
}

.cqc-item-visitor-label {
  font-size: 14px;
  font-weight: 500;
  color: #0077ff;
  margin-top: 5px;
}

.cqc-item-qme-label {
  font-size: 14px;
  font-weight: 500;
  color: #7e3dff;
  margin-top: 5px;
}

.cqc-item-just-gomo-label {
  font-size: 14px;
  font-weight: 500;
  color: #10ad32;
  margin-top: 5px;
}

.cqc-item-orange-label {
  font-size: 14px;
  font-weight: 500;
  color: #f58233;
  margin-top: 5px;
}

.cqc-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.av-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.cqc-qr-container-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cqc-qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  background-color: #ffca28;
  height: fit-content;
  width: fit-content;
  text-align: center;
}

.cqc-qr-name-label-light {
  font-size: 25px;
  margin: 2px 0px;
  text-align: center;
  font-weight: 600;
}

.cqc-powered-label {
  font-weight: 400;
  width: 100%;
  color: var(--black);
  font-style: italic;
  text-align: end;
}

.qr-code-footer {
  margin: 5px 0;
  text-align: center;
  width: 100%;
}

.qr-footer-text {
  text-align: center;
  font-weight: 600;
}

.qr-code {
  position: relative;
}

.qr-code img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}

.cqc-dynamic-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cqc-container1 {
  background-color: #f4fdf3;
  padding: 1.5rem;
  margin-top: 2rem;
}

.cqc-container1-heading1 {
  font-size: 28px;
  margin: 10px 0px 25px 0px;
  font-weight: 600;
  text-align: center;
  color: var(--heading-color);
}

.cqc-sub-heading-label {
  font-size: 18px;
  color: var(--black);
  font-weight: bold;
  text-align: center;
}

.cqc-sub-heading-label-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.cqc-container1-sub-heading-label {
  font-size: 17.5px;
  color: var(--black);
  font-weight: bold;
  font-style: italic;
}

.cqc-container1-text {
  margin: 10px 0px 25px 0px;
}

.cqc-container1-heading-text {
  font-size: 16px;
  text-align: center;
  color: var(--black);
}

.cqc-container1-heading {
  font-size: 18px;
  text-align: center;
  margin: 10px 0px 25px 0px;
  color: var(--black);
}

.cqc-sub-title {
  color: var(--heading-color1);
  font-size: 2.6rem;
  text-align: center;
}

.cqc-content-container-bg {
  background-color: #f5f5f5;
}

@media (max-width: 767px) {
  .cqc-sub-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 567px) {
  .cqc-sub-title {
    font-size: 1.3rem;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .cqc-sub-title {
    font-size: 1.7rem;
  }
}
