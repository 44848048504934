@import url("../../../App.css");

.cd-profile-img-container {
  width: 100px;
  height: 100px;
}

.cd-banner-img-container {
  width: 150px;
  height: 100px;
}

.cp-image-label {
  font-size: 12px;
  color: var(--black);
  margin-top: 5px;
}
