.loader {
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    width: 40px;
  }

  .loader-text {
    font-size: 26px;
    font-weight: 500;
    margin: 0;
    z-index: 5;
    color: white;
    text-align: center;
  }
}
