@import url("../../App.css");

.home-logo-container {
  width: 100px;
  height: 45px;
}

.navbar {
  border-bottom: solid 0.75px #ebebeb;
}

.nav-link {
  color: var(--sub-heading-color1);
  background-color: var(--white);
  font-size: 1.1rem;
  margin-right: 10px;
}

.nav-btn {
  color: var(--sub-heading-color1);
  margin-right: 0px;
  font-size: 1rem;
}

.nav-item-label {
  cursor: pointer;
  color: var(--sub-heading-color1);
}

.nav-item-label:hover {
  font-weight: 600;
}

.nav-item-label-btn {
  cursor: pointer;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  font-size: 1rem;
  border-color: var(--sub-heading-color1);
  color: var(--sub-heading-color1);
  text-align: center;
}

.nav-item-label-btn:hover {
  border-width: 2px;
  border-color: var(--sub-heading-color1);
  font-weight: 600;
  color: var(--sub-heading-color1);
}

.home-logo-container {
  width: 100px;
    height: 50px;
}

@media (max-width: 767px) {
  .home-logo-container {
    width: 87px;
    height: 42px;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .home-logo-container {
    width: 100px;
    height: 48px;
  }
}
