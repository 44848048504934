@import url("../../../App.css");

.cp-card {
  padding: 5px 10px;
  margin-bottom: 10px;
  align-self: center;
}

.cp-input-container {
  width: 200px;
}

.cp-specification-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.cp-row-margin {
  margin-bottom: 5px;
}

.cp-form-icon {
  width: 48px;
  height: 48px;
  margin-left: 15px;
  cursor: pointer;
}

.cp-list-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.cp-option-item-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cp-option-item-col-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  cursor: pointer;
}

.cp-option-item-row {
  display: flex;
  flex-direction: row;
  padding: 5px 0.5em 5px 1em;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f6f7f2;
  border-radius: 4px;
  border-color: #f5f5f0;
  border-style: solid;
  border-width: 0.75px;
  box-shadow: 0 3px 6px lightgrey;
}

.cp-option-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0.5em 5px 1em;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f6f7f2;
  border-radius: 4px;
  border-color: #f5f5f0;
  border-style: solid;
  border-width: 0.75px;
  box-shadow: 0 3px 6px lightgrey;
}

.cp-option-item-name {
  font-size: 15px;
  font-weight: 600;
  color: var(--black);
}

.cp-option-item-description {
  font-size: 12px;
  color: var(--gray);
}

.cp-option-item-normal {
  font-size: 15px;
  color: var(--black);
}

.cp-blue-label {
  color: var(--blue-general);
}
