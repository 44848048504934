@import url("../../../../../App.css");

.ncod-payment-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ncod-payment-row-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: var(--gray);
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  width: 50%;
}

.ncod-display-value {
  font-size: 16px;
  color: var(--black);
}

.ncod-design-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;
}

.ncod-card-design-container {
  width: fit-content;
  height: fit-content;
}

.no-print {
}

.ncod-employee-row-parent-container {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.ncod-employee-row-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  background-color: #f8f8f8;
  padding: 10px 5px;
}

.ncod-employee-row-item-container {
  border-radius: 4px;
  padding: 5px 10px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  background-color: var(--white);
  margin-right: 7px;
}

.ncod-employee-name-label {
  font-size: 17px;
  font-weight: 600;
  color: var(--black);
}

.ncod-employee-designation-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--heading-color1);
}

.ncod-employee-contacy-label {
  font-size: 15px;
  color: var(--black);
}

@media print {
  .no-print {
    display: none;
  }
}
