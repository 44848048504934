@import url("../../../App.css");

.action-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.action-btn-img-container {
  width: 32px;
  height: 32px;
}

.mark-exit-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--heading-color1);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--heading-color1);
  border-width: 0.75px;
  font-size: 14px;
  padding: 5px 15px;
  border-style: solid;
}

th,
td {
  white-space: nowrap;
}

.action-margin-start {
  margin-left: 20px;
}
