@import url("../../../App.css");

.black-font {
  color: var(--heading-color1);
  text-shadow: none;
}

.nhp-sub-title {
  color: var(--heading-color1);
  font-size: 4rem;
  text-align: center;
}

.header-parent-container {
  background-color: #f2f2ff;
  padding: 10px 0px;
}

.header-item-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-text-center {
  text-align: center;
  vertical-align: middle;
}

.header-title {
  font-family: "DM Sans", sans-serif;
}

.nhp-get-started-button {
  border-radius: 10px;
  color: white;
  width: 70%;
  height: 45px;
  font-size: 16px;
  border-width: 0.75px;
  border-style: solid;
  align-self: center;
  border-radius: 5px;
  color: #72727f;
  padding: 5px 3rem;
  font-size: 17px;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #72727f;
  border-width: 0.75px;
  border-style: solid;
}

.nhp-get-started-button:hover {
  color: #72727f;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #72727f;
  border-width: 2px;
  font-weight: bold;
  border-style: solid;
}

.nhp-container1 {
  background-color: #f2f2ff;
  padding: 1.5rem;
  margin-top: 10px;
}

.nhp-container1-heading {
  font-size: 20px;
  text-align: center;
  margin: 10px 0px;
  color: var(--black);
}

.nhp-container1-heading1 {
  font-size: 30px;
  margin: 30px 0px;
  font-weight: 600;
  text-align: center;
  color: var(--heading-color);
}

.nhp-container1-sub-heading {
  font-size: 17px;
  color: var(--black);
  margin: 10px 0px;
}

.nhp-container1-sub-heading-label {
  font-size: 19px;
  color: var(--black);
  font-weight: bold;
}

.nhp-feature-icon-container {
  width: 80px;
  height: 80px;
  margin: 25px;
}

.nhp-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nhp-row-wrap {
  height: fit-content;
}

.nhp-feature-icon-container1 {
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.credit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
  padding: 5px 0px;
  margin-top: 1.5rem;
  background-color: #f2f2ff;
}

.flag-container {
  width: 28px;
  margin-left: 15px;
}

.modal-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-btn-start {
  align-self: flex-start;
  font-style: italic;
  margin-top: 10px;
  color: var(--blue-general);
  font-size: 10.5px;
  margin-bottom: 20px;
}

.or-label-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.or-label {
  font-size: 12px;
  color: var(--gray);
  letter-spacing: 1px;
}

.or-horizontal-line {
  width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  background: var(--gray);
  height: 1px;
}

@media (max-width: 767px) {
  .header-item-container {
    height: 350px;
  }

  .nhp-sub-title {
    font-size: 1.9rem;
  }

  .nhp-get-started-button {
    width: 80%;
  }
}

@media (max-width: 567px) {
  .nhp-sub-title {
    font-size: 1.7rem;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .header-item-container {
    height: 400px;
  }

  .nhp-sub-title {
    font-size: 2.1rem;
  }
}
